import React from 'react';
import {Formik} from "formik";
import InputGroup from "react-bootstrap/InputGroup";
import {FormControl} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from './Login.module.scss';
import Col from "react-bootstrap/Col";
import * as api from './api';
import {RouteComponentProps} from "react-router";
import {WithTranslation, withTranslation} from 'react-i18next';

class PasswordChange extends React.Component<RouteComponentProps<{token: string}> & WithTranslation> {

    private schema = yup.object({
        password1: yup.string().required(),
    });

    changePassword = (doublePasswords: {password1: string}) => {
        api.changePassword(this.props.match.params.token, doublePasswords.password1);
        this.props.history.push('/');
    };

    render() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs={12} sm={6} md={4} lg={4} className={styles.loginForm}>

                        <p>{this.props.t('insertYourPassword')}</p>

                        <Formik validationSchema={this.schema} onSubmit={this.changePassword} initialValues={{password1:""}}>
                            {({
                                  handleSubmit,
                                  handleChange,
                                  values,
                                  touched,
                                  isValid,
                                  errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            type="password"
                                            name="password1"
                                            placeholder="Password"
                                            onChange={handleChange}
                                            value={values.password1}/>
                                    </InputGroup>

                                    <Button variant="primary" disabled={!isValid} className="btn-block" type="submit">
                                        {this.props.t('createAccount')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(PasswordChange);