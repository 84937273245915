import React from 'react';
import {Jumper} from "./api";
import Nav from "./Nav";
import {RouteComponentProps} from "react-router";
import styles from './MyInfo.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import momenttz from 'moment-timezone';

interface MyInfoProps extends RouteComponentProps {
    me: Jumper;
    logout: Function;
}

class MyInfo extends React.Component<MyInfoProps & WithTranslation> {

    logout = () => {
        this.props.logout();
        this.props.history.push('/');
    };

    isValidHealthDeclaration = () =>
      this.props.me.health_certificate_date
        && momenttz(this.props.me.health_certificate_date).isAfter(momenttz.now());

    isVVHValid = () => {
      if (!this.props.me.reserve_practice_pull_date) {
        return false;
      }

      if (['C','D','C HM','D HM'].includes(this.props.me.category)) {
        return momenttz(this.props.me.reserve_practice_pull_date).add(4, 'months').isAfter(momenttz.now())
      } else {
        return momenttz(this.props.me.reserve_practice_pull_date).add(2, 'months').isAfter(momenttz.now())
      }
    }

    render() {
        return (
            <>
                <Nav
                    logout={this.logout}
                    gravatarEmail={this.props.me.email}
                    name={this.props.me.first_name + " " + this.props.me.last_name}
                />
                <div className="container-fluid mb-4">
                    <div className="row">
                        <main className="col-12  bd-content">
                            <h1 className="bd-title">{this.props.t('personalData')}</h1>
                            {this.props.me && (
                                <div className="row">
                                    <div className="col-sm-4">

                                        <div className={"card " + styles.card}>
                                            <div className="card-body">
                                                <h5 className="card-title">{this.props.t('readyToJump')}</h5>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item">
                                                    {this.props.t('license')} {this.props.me.category}
                                                </li>
                                                <li className="list-group-item">
                                                    {this.isVVHValid() ?
                                                        (
                                                            <span>{this.props.t('vvhWasDoneAt')} {this.props.me.reserve_practice_pull_date.substring(0,10)}</span>
                                                        ) : (
                                                            <span className="badge-danger text-wrap text-left">
                                                             <span>{this.props.t('doReservePullPractice')}</span>
                                                         </span>
                                                        )
                                                    }
                                                </li>
                                                <li className="list-group-item">
                                                    {this.props.me.balance < 27 ? (
                                                        <span className="badge-danger">
                                                            {this.props.t('balance') + " " + this.props.me.balance + " EUR"}
                                                        </span>
                                                    ) : (
                                                        this.props.t('balance') + " " + this.props.me.balance + " EUR"
                                                    )

                                                    }
                                                </li>
                                                <li className="list-group-item">
                                                    {this.isValidHealthDeclaration() ?
                                                        (
                                                            <span>{this.props.t('healthManifestOK')}</span>
                                                        ) : (
                                                            <span className="badge-danger">
                                                             <span>{this.props.t('fillHealthManifest')}</span>
                                                         </span>
                                                        )
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div className="col-sm-4">
                                        <div className={"card " + styles.card}>
                                            <div className="card-body">
                                                <h5 className="card-title">{this.props.t('contacts')}</h5>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item">
                                                    {this.props.t('manifestCode')} {this.props.me.manifest_code}
                                                </li>
                                                <li className="list-group-item"><i className="fas fa-phone-square"></i>&nbsp;
                                                    {this.props.me.phone}
                                                </li>
                                                <li className="list-group-item"><i className="fas fa-at"></i>&nbsp;
                                                    {this.props.me.email}
                                                </li>
                                                <li className="list-group-item">
                                                    {this.props.t('relatives')} {this.props.me.relatives}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(MyInfo);
