import React from "react";
import {NavLink} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Gravatar from "react-gravatar";
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './Nav.module.scss';

interface NavProps {
    gravatarEmail?: string;
    name: string;
    logout: any;
}

class Nav extends React.Component<NavProps & WithTranslation> {

    render() {
        return (
            <nav className="navbar navbar-expand navbar-dark bg-dark mb-2">
                <span className="navbar-brand">
                    <i className="far fa-paper-plane" />
                </span>

                <div className="collapse navbar-collapse justify-content-between">
                    <ul className="navbar-nav align-items-center justify-content-between w-100">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/transactions">{this.props.t('account')}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/jumps">{this.props.t('jumps')}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/me">{this.props.t('data')}</NavLink>
                        </li>
                        <li className="nav-item ml-auto">
                            <Dropdown>
                                <Dropdown.Toggle as="a" className="nav-link nav-item" variant="dark" id="dropdown-basic">
                                    <span className={styles.username}>
                                        {this.props.name}
                                    </span>
                                    <Gravatar
                                        email={this.props.gravatarEmail}
                                        className="rounded-circle z-depth-0 mr-1"
                                        size={35}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu alignRight>
                                    <Dropdown.Item as="button" onClick={this.props.logout}>{this.props.t('logOut')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default withTranslation()(Nav);
