import React from 'react';
import {Formik} from "formik";
import {FormControl} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from './Login.module.scss';
import Col from "react-bootstrap/Col";
import * as api from "./api";
import {RouteComponentProps} from "react-router";
import {WithTranslation, withTranslation} from 'react-i18next';

interface LoginProps extends WithTranslation, RouteComponentProps {
    setLogin(user: api.Jumper): void;
}

interface LoginState {
    passwordOK: boolean | undefined;
}

class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = {passwordOK: undefined}
    }

    private schema = yup.object({
        username: yup.string().required().email(),
        password: yup.string().required(),
    });

    login = async (form: api.LoginInput) => {
        try {
            await api.login(form);
            this.setState({passwordOK: undefined});
            const meRes = await api.getMe();
            this.props.setLogin(meRes.data);
            this.props.history.push("/me");
        } catch (err) {
            console.log(err);
            try {
              await api.logError(err);
            } catch (err2) {}
            this.setState({passwordOK: false});
        }
    };

    render() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs={12} sm={6} md={4} lg={4} className={styles.loginForm}>
                        <span>
                            {this.state.passwordOK === false &&
                                (<span className={styles.errorText}>{this.props.t('wrongUsernameOrPassword')}</span>)
                            }
                            &nbsp;
                        </span>
                    <Formik validationSchema={this.schema} onSubmit={this.login} initialValues={{username:"", password:""}}>
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              isValid,
                          }) => (
                        <Form noValidate onSubmit={handleSubmit}>

                            <Form.Group controlId="username">
                                <FormControl
                                    placeholder={this.props.t('email') as string}
                                    name="username"
                                    onChange={handleChange}
                                    value={values.username}
                                    isInvalid={this.state.passwordOK === false}
                                />
                            </Form.Group>
                            <Form.Group controlId="password">
                                <FormControl
                                    type="password"
                                    name="password"
                                    placeholder={this.props.t('password') as string}
                                    onChange={handleChange}
                                    value={values.password}
                                    isInvalid={this.state.passwordOK === false}
                                />
                            </Form.Group>
                            <Button variant="primary" disabled={!isValid} className="btn-block" type="submit">
                                {this.props.t('login')}
                            </Button>
                            <p className="mt-4 text-center">
                                <a href="/signup">{this.props.t('signupOrForgotPassword')}</a>
                            </p>
                            <p className="text-center">
                                <a href="/" onClick={(e) => {e.preventDefault(); this.props.i18n.changeLanguage('et')}}>et</a> /&nbsp;
                                <a href="/" onClick={(e) => {e.preventDefault(); this.props.i18n.changeLanguage('ru')}}>ру</a> /&nbsp;
                                <a href="/" onClick={(e) => {e.preventDefault(); this.props.i18n.changeLanguage('en')}}>en</a>
                            </p>

                        </Form>
                        )}
                    </Formik>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(Login);
