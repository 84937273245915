import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: '/api/',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

export interface Jumper {
  id: number;
  manifest_code: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  email: string;
  phone: string;

  relatives: string;

  category: string;
  health_certificate_date: string;
  reserve_practice_pull_date: string;

  balance: number;
}

export interface Transaction {
    id: number
    transaction_code: number;
    amount: number;
    balance: number;
    description: string;
    jump_id: number;
    created_at: string;
    updated_at: string;
    jumpId: number | null;
    jumpNumber: number | null;
    loadDate: string;
}

export interface Jump {
  jumpNumber: number;
  loadNumber: number;
  rig: string;
  plan: string;
  aircraftCode: string;
  date: string;
}

export interface LoginInput {
    username: string;
    password: string;
}

export function login(form: LoginInput) {
    return axiosInstance.post<any>('login', form, {timeout: 3000} )
}

export function logout() {
    return axiosInstance.post<any>('logout')
}

export function requestToken(email: string) {
    return axiosInstance.post<any>('token', {email: email})
}

export function changePassword(token: string, password: string) {
    return axiosInstance.post('account', {token: token, password: password});
}

export function getMe() {
  return axiosInstance.get<Jumper>('me')
}

export function getMyTransactions(offset: number, limit: number) {
    return axiosInstance.get<Transaction[]>('transactions',{
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function getMyJumps(offset: number, limit: number) {
  return axiosInstance.get<Jump[]>('jumps',{
    params: {
      offset: offset,
      limit: limit
    }
  });
}

// todo replae with proper ui logging service when available
export function logError(error: string) {
  return axiosInstance.get<any>('log', {
    params: {
      error: error
    }
  })
}
