import {TheState} from "./types";

export function reducer(state = {}, action: any): TheState {
    switch (action.type) {
        case 'hua':
            return {
                ...state,
                hua: action.hua
            };
        default:
            return state;
    }
}
