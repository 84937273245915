import React from 'react';
import Container from "react-bootstrap/Container";
import { useTranslation } from 'react-i18next';

export const SignupConfirmation = () => {
    const { t } = useTranslation();
  return <Container>
      <h1>{t('confirmationHeader')}</h1>

      <p>{t('confirmationEmailSent')}</p>
      <p>{t('confirmationEmailSent2')}</p>
      <p>{t('confirmationEmailSent3')}</p>
      <p>{t('confirmationEmailSent4')}</p>
  </Container>
};
