import React from 'react';
import {getMyJumps, Jump, Jumper} from "./api";
import Nav from "./Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import styles from './Transactions.module.scss';
import {RouteComponentProps} from "react-router";
import {WithTranslation, withTranslation} from 'react-i18next';

interface JumpsProps extends RouteComponentProps {
  me: Jumper;
  logout: Function;
}

interface JumpsState {
  jumps: Jump[];
}

function TableRow({row}:any) {
  return (
    <tr>
      <th scope="row">{row.date.substring(0, 10)}</th>
      <td className="text-left text-md-right">{row.jumpNumber}</td>
      <td className="text-left text-md-right">{row.aircraftCode}</td>
      <td className={styles.hiddenMdDown + " text-right"}>{row.loadNumber}</td>
      <td className={styles.hiddenMdDown}>{row.command}</td>
      <td className={styles.hiddenMdDown}>{row.plan}</td>
    </tr>
  )
};

class Jumps extends React.Component<JumpsProps & WithTranslation, JumpsState> {
  constructor(props: any) {
    super(props);
    this.state = {"jumps": []}
  }

  logout = () => {
    this.props.logout();
    this.props.history.push('/');
  };


  componentDidMount(): void {
    getMyJumps(0, 100).then(
      results => {
        this.setState({"jumps": results.data});
      }
    );
  }

  render() {
    return (
      <>
        <Nav
          logout={this.logout}
          gravatarEmail={this.props.me.email}
          name={this.props.me.first_name + " " + this.props.me.last_name}
        />
        <Container fluid className={styles.containerFluid}>
          <Row>
            <main className={styles.col12 + " col-12  bd-content"}>
              <h1 className="bd-title">{this.props.t('jumps')}</h1>
              <Table hover size="sm">
                <thead>
                <tr>
                  <th scope="col">{this.props.t('date')}</th>
                  <th scope="col" className="text-left text-md-right">{this.props.t('jumpNumber')}</th>
                  <th scope="col" className="text-left text-md-right">{this.props.t('aircraft')}</th>
                  <th
                    scope="col"
                    className={`${styles.hiddenMdDown}  text-right`}
                  >
                    {this.props.t('loadNumber')}
                  </th>
                  <th scope="col" className={styles.hiddenMdDown}>{this.props.t('command')}</th>
                  <th scope="col" className={styles.hiddenMdDown}>{this.props.t('plan')}</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state &&
                  this.state.jumps &&
                  this.state.jumps.map((row) => {
                    return <TableRow row={row} key={row.jumpNumber}/>
                  })
                }
                </tbody>
              </Table>
            </main>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation()(Jumps);
