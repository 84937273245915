import React from 'react';
import {BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import './App.css';
import MyInfo from "./MyInfo";
import Transactions from "./Transactions";
import Login from "./Login";
import Signup from "./Signup";
import PasswordChange from "./PasswordChange";
import * as api from "./api";
import {Jumper} from "./api";
import {Provider} from "react-redux";
import configureStore from './store';
import {SignupConfirmation} from "./SignupConfirmation";
import Jumps from "./Jumps";

const store = configureStore();

interface State {
    user?: Jumper | null;
}

const PrivateRoute = ({component: Component , isAuthenticated, ...rest}: any) => (
    <Route
        {...rest}
        render={ (props) => (isAuthenticated() === true ? <Component {...props}/> : <Redirect to='/' />) }
    />
);

const PublicToPrivateRoute = ({component: Component , isAuthenticated, ...rest}: any) => (
    <Route
        {...rest}
        render={ (props) => (isAuthenticated() === true ? <Redirect to='/me' /> : <Component {...props}/> ) }
    />
);


class App extends React.Component<any, State> {
  constructor(props: any) {
      super(props);
      this.state = {};

      api.getMe()
          .then(res => this.setState({user: res.data}))
          .catch(() => this.setState({user: null}));
  }

  isAuthenticated = () => {
      const { user } = this.state;
      return user !== undefined && user !== null;
  };

  loginStateKnown = () => {
      const { user } = this.state;
      return user !== undefined
  };

  setLoginState = (user: api.Jumper) => {
      this.setState({user: user});
  };

  logout = () => {
      this.setState({user: null});
      api.logout();
  };

  render() {
    if (this.loginStateKnown()) {
        return (
            <Provider store={store}>
                <Router>
                    <div>
                        <PublicToPrivateRoute path='/' exact component={(psps: any) => {return <Login {...psps} setLogin={this.setLoginState} />}} isAuthenticated={this.isAuthenticated}/>
                        <Route path='/signup' exact component={Signup}/>
                        <Route path='/signupConfirmation' exact component={SignupConfirmation}/>
                        <Route path='/signup/:token' exact component={PasswordChange}/>
                        <PrivateRoute path='/me' exact component={(psps: any) => {return <MyInfo {...psps} me={this.state.user} logout={this.logout} />}} isAuthenticated={this.isAuthenticated} />
                        <PrivateRoute path='/transactions' exact component={(psps: any) => {return <Transactions {...psps} me={this.state.user} logout={this.logout} />}} isAuthenticated={this.isAuthenticated}/>
                        <PrivateRoute path='/jumps' exact component={(psps: any) => {return <Jumps {...psps} me={this.state.user} logout={this.logout} />}} isAuthenticated={this.isAuthenticated}/>
                    </div>
                </Router>
            </Provider>
        );
    } else {
        return (<p>Loading...</p>);
    }
  }
}

export default App;
