import React from 'react';
import {getMyTransactions, Jumper, Transaction} from "./api";
import Nav from "./Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import styles from './Transactions.module.scss';
import {RouteComponentProps} from "react-router";
import {WithTranslation, withTranslation} from 'react-i18next';

interface TransactionsProps extends RouteComponentProps {
    me: Jumper;
    logout: Function;
}

interface TransactionsState {
    transactions: Transaction[];
}

function TableRow({row}: any) {
    return (
        <tr>
            <th scope="row">{row.created_at.substring(0, 10)}</th>
            <td className={styles.hiddenMdDown}>{row.first_name} {row.last_name}</td>
            <td className="text-left text-md-right">{row.amount}</td>
            <td className={styles.hiddenMdDown + " text-right"}>{row.balance}</td>
            <td>
                <div className={styles.fixWidth}>{row.loadDate && (row.created_at.substring(0, 10) !== row.loadDate.substring(0, 10)) && row.loadDate.substring(0, 10)} {row.description}</div>
            </td>
        </tr>
    )
};

class Transactions extends React.Component<TransactionsProps & WithTranslation, TransactionsState> {
    constructor(props: any) {
        super(props);
        this.state = {"transactions": []}
    }

    logout = () => {
        this.props.logout();
        this.props.history.push('/');
    };


    componentDidMount(): void {
        getMyTransactions(0, 100).then(
            results => {
                this.setState({"transactions": results.data});
            }
        );
    }

    render() {
        return (
            <>
                <Nav
                    logout={this.logout}
                    gravatarEmail={this.props.me.email}
                    name={this.props.me.first_name + " " + this.props.me.last_name}
                />
                <Container fluid className={styles.containerFluid}>
                    <Row>
                        <main className={styles.col12 + " col-12  bd-content"}>
                            <h1 className="bd-title">{this.props.t('transactions')}</h1>
                            <Table hover size="sm">
                                <thead>
                                    <tr>
                                        <th scope="col">{this.props.t('date')}</th>
                                        <th scope="col" className={styles.hiddenMdDown}>{this.props.t('party')}</th>
                                        <th scope="col" className="text-left text-md-right">{this.props.t('sum')}</th>
                                        <th
                                            scope="col"
                                            className={`${styles.hiddenMdDown}  text-right`}
                                        >
                                            {this.props.t('balance')}
                                        </th>
                                        <th scope="col">{this.props.t('description')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state &&
                                        this.state.transactions &&
                                        this.state.transactions.map((row) => {
                                            return <TableRow row={row} key={row.id}/>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </main>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withTranslation()(Transactions);
