import en from './en.json';
import et from './et.json';
import ru from './ru.json';

export default {
    en: {
        translation: {
            ...en,
        }
    },
    et: {
        translation: {
            ...et,
        }

    },
    ru: {
        translation: {
            ...ru,
        }
    }
};
