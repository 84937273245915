import React from 'react';
import {Formik} from "formik";
import {FormControl} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from './Login.module.scss';
import Col from "react-bootstrap/Col";
import {requestToken} from "./api";
import {RouteComponentProps} from "react-router";
import {WithTranslation, withTranslation} from 'react-i18next';

class Signup extends React.Component<RouteComponentProps & WithTranslation> {

    private schema = yup.object({
        username: yup.string().required().email(),
    });

    signup = (form: {username: string}) => {
        requestToken(form.username);
        this.props.history.push('/signupConfirmation');
    };

    render() {
        return (
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs={12} sm={6} md={4} lg={4} className={styles.loginForm}>

                        <p>{this.props.t('signupInformation')}</p>

                        <Formik validationSchema={this.schema} onSubmit={this.signup} initialValues={{username: ""}}>
                            {({
                                  handleSubmit,
                                  handleChange,
                                  values,
                                  isValid,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId="username">
                                        <FormControl
                                            type="text"
                                            placeholder={this.props.t('email') as string}
                                            name="username"
                                            onChange={handleChange}
                                            value={values.username}
                                        />
                                    </Form.Group>

                                    <Button variant="primary" disabled={!isValid} className="btn-block" type="submit">
                                        {this.props.t('signupConfirmMyEmailAddress')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(Signup);